@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  

  h1 {
    font-weight: 500;
  }

  p {
    /* word-spacing: 2em; */
    line-height: 2em;
  }

  /* .auto-table {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20em, max-content));
  } */

  .form > * {
    padding:0.5em;
    margin:0.5em;
    width:25em;
  }